import './css/loading.scss';
import anime from 'animejs/lib/anime.es.js';

const loadingEl = document.querySelector('.loading');

const loadingAnime = anime
  .timeline()
  .add({
    targets: '.loading--abstract',
    opacity: 1,
    duration: 700,
    endDelay: 300,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading--logo',
    opacity: 1,
    duration: 700,
    endDelay: 700,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading--logo',
    opacity: 0,
    duration: 700,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading--statement',
    opacity: 1,
    duration: 700,
    endDelay: 700,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading--statement',
    opacity: 0,
    duration: 700,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading--abstract',
    opacity: 0,
    duration: 700,
    easing: 'easeInOutSine',
  })
  .add({
    targets: '.loading',
    opacity: 0,
    duration: 300,
    easing: 'easeInOutSine',
  });

loadingAnime.finished.then(function () {
  loadingEl.parentNode.removeChild(loadingEl);
});
